import React, { useEffect } from 'react'
import Images from '../helpers/Images';
import { CardBox } from './common/CardBox';
import './Prices.css'

const Prices = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const cards = [
    {
      title: 'Alertas',
      price: '$399/año',
      description: 'Suscripción anual de Alertas Vehiculares para autos y motos con placas de: CDMX, EdoMex y Morelos. ',
      list: [
        'Hoy No Circula',
        'Verificación',
        'Tenencia / Refrendo',
        'Infracciones',
        'Sanciones Ambientales',
        'FotoCívicas',
        'Tarjeta de Circulación',
        'Renovación de Placas',
      ],
      note: '* Incluye un reporte mensual vía mail',
    },
    {
      plus: true,
      title: 'Alertas Plus',
      icon: Images.outo.crown,
      price: '$699/año',
      description: 'Suscripción anual de Alertas Vehiculares para autos y motos con placas de: CDMX, EdoMex y Morelos. ',
      list: [
        'Hoy No Circula',
        'Verificación',
        'Tenencia / Refrendo',
        'Infracciones',
        'Sanciones Ambientales',
        'FotoCívicas',
        'Tarjeta de Circulación',
        'Renovación de Placas',
      ],
      note: '* Incluye un reporte mensual vía mail',
      additional_text: 'Además incluyen 5 servicios de Emergencia Vial con cobertura a nivel nacional',
      additional_list: [
        'Grúa (Local)',
        'Auxilio Vial',
        'Abogado Vial',
        'Ambulancia',
        'Asistencia Legal',
      ]
    },
    {
      title: 'Emergencia Vial',
      price: '$499/año',
      description: 'Suscripción anual de Alertas Vehiculares para autos y motos con placas de: CDMX, EdoMex y Morelos. ',
      list: [
        'Grúa (Local)',
        'Auxilio Vial',
        'Abogado Vial',
        'Ambulancia',
        'Asistencia Legal'
      ],
    },
  ]

  return (
    <>
      <div className='prices_background'>
        <div className='middle_color'>
        </div>
        <div className='prices'>
          <div className={'prices_intro'}>
            <h1 className='section_subtitle'>Precios</h1>
            <p className='section_description'>Contrata tu plan de suscripción anual con los mejores precios y condiciones del mercado.</p>
          </div>
          <div>
            <img className='vertical_dots back_dots' alt='dots' src={Images.outo.background_dots} />
            <img className='horizontal_dots back_dots desktop' alt='dots' src={Images.outo.background_dots} />
            <div className='cardbox_container'>
              {
                cards.map(({ plus, title, icon, price, description, list, note, additional_text, additional_list }, i) => (
                  <CardBox
                  key={`card_box${i}`}
                    plus={plus}
                    title={title}
                    icon={icon}
                    price={price}
                    description={description}
                    list={list}
                    note={note}
                    additional_text={additional_text}
                    additional_list={additional_list}
                  />
                ))
              }
            </div>
          </div>
          <div>
            <p className='note'>* Los servicios de Emergencia Vial serán válidos al haber transcurrido un mínimo de quince (15) días posteriores al pago de la suscripción.</p>
          </div>
        </div>
      </div>
    </>

  )
}

export default Prices;

