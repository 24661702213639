import { createSlice } from "@reduxjs/toolkit";
const emergency = {
  title: 'Términos y Condiciones de Emergencia Vial',
  intro: [
    'Estos Términos y Condiciones de Servicio regulan el uso y la contratación de los servicios de Emergencia Vial ofrecidos en la aplicación móvil Outo®, propiedad de Alerta Digital, S.A.P.I. de C.V., y que podrán ser contratados vía digital o telefónica. ',
    'Al contratar los Servicios ofrecidos en la aplicación móvil o en el sitio web o indicar de otro modo su aceptación (por ejemplo, al aceptar registrar un vehículo o iniciar sesión en su cuenta), Usted declara y garantiza que ha leído, comprende y acepta estar obligado por estos Términos. Si no está de acuerdo, no descargue ni utilice la App ni los servicios. '
  ],
  clauses: [
    {
      title: '1.- Servicio de Emergencia Vial ',
      content: [
        'Grúa (Local)',
        'Servicio de Grúa cuando tu vehículo registrado en Outo no pueda circular por una falla mecánica o avería.',
        'El servicio incluye un servicio de Grúa por año a nivel nacional.',
        [
          'Incluye 1 servicio de Grúa al año.',
          'El traslado es al taller más cercano o a un lugar elegido dentro de la Ciudad donde ocurra la avería o falla mecánica.',
          'Atención 24 hrs del día / 365 días al año.',
          'Si el arrastre excede los 30km el usuario pagará el km adicional excedente.',
          'En caso de requerir un servicio carretero, la diferencia del precio se pagará directamente al momento de realizarse el servicio.',
        ],

        'Exclusiones: ',
        'Quedan excluidos los vehículos destinados al transporte de carga, taxis, microbuses, colectivos, autobuses, autos modificados y vehículos arrendados. El servicio no cubre casetas, maniobras, abanderamientos y tiempo de espera. El Usuario deberá estar presente en todo momento mientras se realiza el arrastre y/o asistencia.',
        'Auxilio Vial',
        'Servicio de Auxilio Vial en caso de requerir asistencia con el cambio de llanta, suministro de gasolina o paso de corriente.',
        'El servicio incluye un evento al año a nivel nacional.',
        [
          'Los eventos pueden ser: cambio de llanta, suministro de gasolina o paso de corriente.',
          'En caso de suministro de gasolina, el usuario tiene incluido hasta un máximo de 10 lts. de gasolina sin costo adicional.',
          'En caso de cambio de llanta, el usuario debe contar con el birlo de seguridad y una llanta de refacción en buenas condiciones.',
          'Atención 24 hrs del día / 365 días al año',
        ],
        'Exclusiones: ',
        'El servicio no cubre casetas, maniobras, abanderamientos y tiempo de espera. El Usuario deberá estar presente en todo momento mientras se realiza la asistencia.',
        'Abogado Vial',
        'Si te detiene un Policía o quedas a disposición de alguna autoridad a consecuencia de un percance de tránsito, nuestros abogados te asesorarán vía telefónica para tu defensa.',
        'El servicio incluye un evento por año a nivel nacional.',
        'Asesoría y orientación telefónica sobre la correcta aplicación del reglamento de tránsito en caso de que te detenga un Policía.',
        'Asesoría y orientación legal en caso de tener un problema vial o infracción de tránsito.',
        'Asesoría para trámites relativos a la liberación del conductor y del vehículo involucrado.',
        'Atención 24 hrs del día / 365 días al año.',
        'Exclusiones: ',
        'Outo quedará eximido del cumplimiento de las obligaciones en los siguientes casos: ',
        [
          'Cuando el usuario no proporcione información veraz y oportuna, que por su naturaleza no permita al abogado evaluar debidamente el asunto. '
        ],
        'Outo no cubrirá el importe de las multas por infracciones al reglamento de tránsito y derechos de uso de piso (corralón). ',
        'Outo no se hace responsable de cubrir la responsabilidad civil (daños en propiedad ajena), en que incurra el usuario y/o beneficiarios, por algún hecho relacionado con el tránsito terrestre, esto quiere decir, que Outo no se hace responsable del pago de daños causados a terceros, tanto en sus personas como en sus bienes, ni del pago de indemnizaciones ni garantías civiles que se llegaran a generar a cargo del usuario y/o beneficiarios por tales hechos. ',
        'Ambulancia',
        'En caso de enfermedad repentina, urgencia médica o accidente, te enviaremos una ambulancia terrestre para traslado al centro hospitalario más cercano.',
        'El servicio incluye un evento por año a nivel nacional.',
        [
          'El traslado terrestre es hacia el centro hospitalario más cercano o apropiado en la ciudad donde se encuentre y que se trate de un servicio de emergencia.',
          'Incluye el traslado del usuario principal o un acompañante.',
          'Atención 24 hrs del día / 365 días al año.',
        ],
        ' Exclusiones:',
        [
          'Quedan excluidos los traslados en caso de enfermedades mentales.',
          'Pacientes en estado de ebriedad o con abuso de alguna droga que se encuentren en estado agresivo o no deseen ser trasladados. ',
          'Cuando el usuario no proporcione información veraz y oportuna, o que incurra en falsedad de información en relación con el tipo de lesiones. ',
          'Ambulancias programadas. ',
          'Ambulancias aéreas.',
        ],
        'Asistencia Legal',
        'Si te detiene un Policía o quedas a disposición de alguna autoridad a consecuencia de un percance de tránsito, enviaremos a un abogado presencial al lugar de los hechos para tu defensa legal.',
        'El servicio incluye un evento por año a nivel nacional.',
        [
          'Servicio profesional de abogados especialistas en asuntos vehiculares judiciales / administrativos.',
          'Defensa y/o asesoría ante las autoridades por la comisión de delitos culposos en materia de responsabilidad derivados del tránsito terrestre de vehículos.',
          'Atención 24 hrs del día / 365 días al año.',
        ],
        'Exclusiones: ',
        'No incluye fianzas ni cauciones. ',
        'Outo quedará eximido del cumplimiento de las obligaciones en los siguientes casos: ',
        [
          'Cuando el usuario no proporcione información veraz y oportuna, que por su naturaleza no permita al abogado evaluar debidamente el asunto. ',
          'Cuando el usuario no acuda a las audiencias o citatorios realizados por la autoridad. ',
          'Cuando el usuario no atienda la asesoría y recomendaciones realizadas por el abogado para su defensa, o realice arreglos personales sin consultar previamente al profesionista mencionado. ',
          'Cuando el usuario designe un abogado distinto a los proporcionados por Outo, lo que implique que esta empresa pierda el control del asunto. ',
          'En los casos en que el usuario y/o beneficiarios conduzcan vehículos con capacidad de carga de 3.5 toneladas en adelante, vehículos de transporte público de carga y de personas tanto federal como particular (taxis, microbuses, colectivos, autobuses y vehículos arrendados). ',
        ],
        'Outo no cubrirá el importe de las multas por infracciones al reglamento de tránsito y derechos de uso de piso (corralón). ',
        'Outo no se hace responsable de cubrir la responsabilidad civil (daños en propiedad ajena), en que incurra el usuario y/o beneficiarios, por algún hecho relacionado con el tránsito terrestre, esto quiere decir, que Outo no se hace responsable del pago de daños causados a terceros, tanto en sus personas como en sus bienes, ni del pago de indemnizaciones ni garantías civiles que se llegaran a generar a cargo del usuario y/o beneficiarios por tales hechos. ',
        'Los servicios de Emergencia Vial serán válidos y aplicables al haber transcurrido un mínimo de quince (15) días posteriores al pago del servicio. ',

      ],
    },
    {
      title: '2.- Costos:',
      content: [
        'El costo de la suscripción anual del servicio de Emergencia Vial es de: $499 pesos (CUATROCIENTOS NOVENTA Y NUEVE PESOS 00/100 M.N.). El precio no incluye I.V.A. ',
        'La renovación de la suscripción se realiza cada año y los servicios no son acumulables.',
        'Los precios están sujetos a cambio sin previo aviso, los cuales serán actualizados en la aplicación móvil de Outo o sitio web www.outo.mx.',
      ]
    },
    {
      title: '3.- Pagos',
      content: [
        'El pago de la suscripción anual se puede realizar por medio de los siguientes métodos de pago:',
        [
          'Tarjetas de débito / crédito',
          'PayPal'
        ],
        'Las formas de pagos y condiciones podrán variar sin previo aviso, las cuales serán actualizadas en la aplicación móvil de Outo o sitio web www.outo.mx.',
      ]
    },
    {
      title: '4.- Reembolsos',
      content: [
        'La suscripción del servicio de Emergencia Vial se pagan de forma anual por adelantado, por lo tanto, no hay reembolsos ni devoluciones de dinero, ni tampoco reembolsos por fracciones de periodo de pago por uso de los servicios.',
      ]
    },
    {
      title: '5.- Responsabilidades',
      content: [
        'Outo no se hace responsable de realizar ningún servicio cuando:',
        [
          'El usuario no se identifique como usuario activo de la plataforma. ',
          'El usuario no proporcione información veraz y no se permita atender debidamente el servicio o incurra en falsedad de declaraciones.',
          'Cuando las placas registradas por el usuario no son de los Estados participantes y no seleccione participar como usuario Foráneo.',
          'Cuando las placas registradas por el usuario son Federales.',
          'Por disposición de la Autoridad Federal, Estatal o local y/o protocolos emitidos por Autoridades correspondientes en México.',
        ],
        'Outo no se hace responsable de las notificaciones enviadas a los usuarios ni tampoco si el vehículo registrado no cumple con sus obligaciones y esto le genera multas o recargos. Tampoco nos hacemos responsables de los pagos pendientes según las obligaciones del usuario y requisitos que tiene que cumplir en cada Estado.',
        'Outo podrá rechazar cualquier solicitud que considere deshonesta o de mala fe por parte del usuario.',
        'Para cualquier duda o aclaración, escríbanos a hola@outo.mx',
      ]
    }
  ]
}

const managmentConditions = {
  title: 'Términos Y Condiciones del Servicio de Gestoría',
  intro: [
    'Estos Términos y Condiciones de Servicio regulan el uso y la contratación de los Servicios de Gestoría o trámites vehiculares ofrecidos en la aplicación móvil Outo®, propiedad de Alerta Digital, S.A.P.I. de C.V., y que podrán ser contratados vía digital o telefónica. ',
    'Al contratar los Servicios ofrecidos en la aplicación móvil o en el sitio web o indicar de otro modo su aceptación (por ejemplo, al aceptar registrar un vehículo o iniciar sesión en su cuenta), Usted declara y garantiza que ha leído, comprende y acepta estar obligado por estos Términos. Si no está de acuerdo, no descargue ni utilice la App ni los servicios. ',
  ],
  clauses: [
    {
      title: '1.- Servicio de Gestoría ',
      content: [
        'Al contratar la suscripción de Gestoría, te ayudamos con tus trámites vehiculares a nivel nacional que incluye cualquiera de los siguientes servicios:',
        [
          'Alta o Baja de vehículos nuevos o usados',
          'Alta o Baja de vehículos híbridos nuevos o usados',
          'Asistencia Legal por Siniestro y Faltas Administrativas',
          'Pago de Tenencias',
          'Pago de Multas vehiculares y sanciones ambientales',
          'Acta por pérdida de placa y/o tarjeta de circulación y/o facturas o acta por robo P.F.P. ',
          'Reemplacamiento en años posteriores al primero',
          'Reposición de tarjeta de circulación.',
          'Regularización de vehículo con registro de robo',
          'Constancias de no infracción Estatal / Municipal',
          'Corrección de datos y cambio de propietario del vehículo',
          'Inscripción del vehículo al REPUVE',
        ],
        'Exclusiones: ',
        'El servicio de Gestoría aplica a nivel nacional, no incluye el pago de multas, impuestos o infracciones pendientes de pago.',
        'Outo quedará eximido del cumplimiento de las obligaciones en los siguientes casos: ',
        [
          'Si el beneficiario no entrega la documentación requerida, no podrá efectuarse el trámite vehicular, quedando prohibida la entrega de cualquier monto por concepto de gratificación. ',
          'Si el beneficiario se retrasa con la entrega de la documentación requerida o entrega datos erróneos, Outo no se responsabilizará por la duración del trámite administrativo. ',
          'Para aquellos estados en donde sea obligatoria la revisión física del vehículo, Outo no se responsabilizará por la no presentación. En caso de tener alguna irregularidad o este reportado como robado, no procederá el servicio y deslindará de toda responsabilidad al gestor y a Outo. ',
          'No se podrá realizar la gestión del trámite vehicular en aquellos casos en los que no sea vehículo del titular. ',
          'Cuando por una acción intencional del beneficiario resulte la comisión de un delito.',
          'Cuando el beneficiario no proporcione información veraz y oportuna, que por su naturaleza no permita atender debidamente el asunto, o bien incurra en falsedad de declaraciones.',
          'Cuando el beneficiario incumpla cualesquiera de las obligaciones indicadas en el presente documento.',
          'No se realizará trámites contenciosos ante autoridades administrativas. ',
          'Cuando el beneficiario se encuentre en el extranjero. ',
          'Si el beneficiario cae en falsedad de declaraciones. ',
          'Cuando el beneficiario no efectúe el pago correspondiente de los trámites solicitados. ',
          'Cualquier reclamación relativa a una gestoría, deberá de ser presentada a Outo, dentro de los 90 días naturales de la fecha en la que se produzca, prescribiendo de cualquier acción legal, transcurrido dicho plazo. ',
          'Todas las solicitudes de servicio emitidas por el usuario a Outo podrán ser grabadas para efectos de calidad en el servicio. ',
          'Cuando por una acción intencional del usuario resulte la comisión de un delito.',
          'Cuando el usuario no se acredite como titular que adquirió el programa. ',
          'Cuando el usuario haga mal uso de los servicios o intente beneficiarse económicamente de estos. ',
          'Confiscación o daños causados por orden de cualquier gobierno, autoridad pública u oficial de aduanas; riesgo de contrabando; actos o actividades ilegales. ',
          'También quedan excluidos los tramites que sean consecuencia directa o indirecta de: huelgas, guerra, invasión, actos de enemigos extranjeros, hostilidades (se haya declarado la guerra o no), rebelión, guerra civil, insurrección, terrorismo, pronunciamientos, manifestaciones, movimientos populares, radioactividad o cualquier otra causa de fuerza mayor; autolesiones o participación del usuario en actos delictuosos intencionales. ',
        ]
      ]
    },
    {
      title: '2.- Costos',
      content: [
        'El costo del servicio de la suscripción anual de Gestoría es de: $899 pesos (OCHOCIENTOS NOVENTA Y NUEVE PESOS 00/100 M.N.). El precio no incluye I.V.A. ',
        'Los precios están sujetos a cambio sin previo aviso, los cuales serán actualizados en la aplicación móvil de Outo o sitio web www.outo.mx.',
      ]
    },
    {
      title: '3.- Pagos',
      content: [
        'El pago de la suscripción anual de Gestoría se puede realizar por medio de los siguientes métodos de pago:',
        [
          'Tarjetas de débito / crédito',
          'PayPal',
        ],
        'Las formas de pagos y condiciones podrán variar sin previo aviso, las cuales serán actualizadas en la aplicación móvil de Outo o sitio web www.outo.mx.'
      ]
    },
    {
      title: '4. Reembolsos',
      content: [
        'En el caso de la contratación de la suscripción de Gestoría, no hay reembolsos ni devoluciones de dinero, ni tampoco reembolsos por fracciones de periodo de pago por uso del servicio.'
      ]
    },
    {
      title: '5. Responsabilidades',
      content: [
        'Outo no se hace responsable de realizar el servicio de Gestoría cuando:',
        [
          'El usuario no se identifique como usuario activo de la plataforma. ',
          'El usuario no proporcione información veraz y no se permita atender debidamente el servicio o incurra en falsedad de declaraciones.',
          'Por disposición de la Autoridad Federal, Estatal o local y/o protocolos emitidos por Autoridades correspondientes en México.',
        ],
        'Outo podrá rechazar cualquier solicitud que considere deshonesta o de mala fe por parte del usuario.',
        'Para cualquier duda o aclaración, escríbanos a hola@outo.mx'
      ]
    }
  ]
}
const alertConditions = {
  title: 'Términos Y Condiciones de Alertas',
  intro: [
    'Estos Términos y Condiciones de Servicio regulan el uso y la contratación de las Alertas Vehiculares ofrecidos en la aplicación móvil Outo®, propiedad de Alerta Digital, S.A.P.I. de C.V., y que podrán ser contratados vía digital o telefónica. ',
    'Al contratar los Servicios ofrecidos en la aplicación móvil o en el sitio web o indicar de otro modo su aceptación (por ejemplo, al aceptar registrar un vehículo o iniciar sesión en su cuenta), Usted declara y garantiza que ha leído, comprende y acepta estar obligado por estos Términos. Si no está de acuerdo, no descargue ni utilice la App ni los servicios. ',
  ],
  clauses: [
    {
      title: '1.- Servicio de Alertas ',
      content: [
        'Recibe notificaciones en tiempo real cuando te pongan una multa, sanción ambiental o fotocívica, tengas que pagar tu tenencia, verificar tu auto, renovar tus placas o la tarjeta de circulación, tengas un reporte de robo o simplemente cuando no circules.',
        'El servicio de Alertas está disponible para autos y motos con placas registradas en Ciudad de México, Estado de México y Morelos.',
        'El servicio incluye:',
        [
          'Notificaciones en tiempo real (push notifications) en tu celular.',
          'Reporte mensual por correo electrónico con el estatus actual de tu auto.',
        ],
        'Exclusiones: ',
        'Quedan excluidos los vehículos con placas Federales o placas de Estados no participantes. El servicio no incluye el pago de multas, infracciones o impuestos.',
      ]
    },


    {
      title: '2.- Servicio de Alertas Plus',
      content: [
        'Incluye el servicio de Alertas + 5 servicios de Emergencia Vial a nivel nacional. Los servicios de Emergencia Vial son los siguientes:',
        'Grúa (Local)',
        'Servicio de Grúa cuando tu vehículo registrado en Outo no pueda circular por una falla mecánica o avería.',
        'El servicio incluye un servicio de Grúa por año a nivel nacional.',
        [
          'Incluye 1 servicio de Grúa al año.',
          'El traslado es al taller más cercano o a un lugar elegido dentro de la Ciudad donde ocurra la avería o falla mecánica.',
          'Atención 24 hrs del día / 365 días al año.',
          'Si el arrastre excede los 30km el usuario pagará el km adicional excedente.',
          'En caso de requerir un servicio carretero, la diferencia del precio se pagará directamente al momento de realizarse el servicio.',
        ],
        'Exclusiones: ',
        'Quedan excluidos los vehículos destinados al transporte de carga, taxis, microbuses, colectivos, autobuses, autos modificados y vehículos arrendados. El servicio no cubre casetas, maniobras, abanderamientos y tiempo de espera. El Usuario deberá estar presente en todo momento mientras se realiza el arrastre y/o asistencia.',
        'Auxilio Vial',
        'Servicio de Auxilio Vial en caso de requerir asistencia con el cambio de llanta, suministro de gasolina o paso de corriente.',
        'El servicio incluye un evento al año a nivel nacional.',
        [
          'Los eventos pueden ser: cambio de llanta, suministro de gasolina o paso de corriente.',
          'En caso de suministro de gasolina, el usuario tiene incluido hasta un máximo de 10 lts. de gasolina sin costo adicional.',
          'En caso de cambio de llanta, el usuario debe contar con el birlo de seguridad y una llanta de refacción en buenas condiciones.',
          'Atención 24 hrs del día / 365 días al año',
        ],
        'Exclusiones: ',
        'El servicio no cubre casetas, maniobras, abanderamientos y tiempo de espera. El Usuario deberá estar presente en todo momento mientras se realiza la asistencia.',
        'Abogado Vial',
        'Si te detiene un Policía o quedas a disposición de alguna autoridad a consecuencia de un percance de tránsito, nuestros abogados te asesorarán vía telefónica para tu defensa.',
        'El servicio incluye un evento por año a nivel nacional.',
        [
          'Asesoría y orientación telefónica sobre la correcta aplicación del reglamento de tránsito en caso de que te detenga un Policía.',
          'Asesoría y orientación legal en caso de tener un problema vial o infracción de tránsito.',
          'Asesoría para trámites relativos a la liberación del conductor y del vehículo involucrado.',
          'Atención 24 hrs del día / 365 días al año.',
        ],
        'Exclusiones: ',
        'Outo quedará eximido del cumplimiento de las obligaciones en los siguientes casos: ',
        [
          'Cuando el usuario no proporcione información veraz y oportuna, que por su naturaleza no permita al abogado evaluar debidamente el asunto. ',
        ],
        'Outo no cubrirá el importe de las multas por infracciones al reglamento de tránsito y derechos de uso de piso (corralón). ',
        'Outo no se hace responsable de cubrir la responsabilidad civil (daños en propiedad ajena), en que incurra el usuario y/o beneficiarios, por algún hecho relacionado con el tránsito terrestre, esto quiere decir, que Outo no se hace responsable del pago de daños causados a terceros, tanto en sus personas como en sus bienes, ni del pago de indemnizaciones ni garantías civiles que se llegaran a generar a cargo del usuario y/o beneficiarios por tales hechos. ',
        'Ambulancia',
        'En caso de enfermedad repentina, urgencia médica o accidente, te enviaremos una ambulancia terrestre para traslado al centro hospitalario más cercano.',
        'El servicio incluye un evento por año a nivel nacional.',
        [
          'El traslado terrestre es hacia el centro hospitalario más cercano o apropiado en la ciudad donde se encuentre y que se trate de un servicio de emergencia.',
          'Incluye el traslado del usuario principal o un acompañante.',
          'Atención 24 hrs del día / 365 días al año.',
        ],
        'Exclusiones: ',
        [
          'Quedan excluidos los traslados en caso de enfermedades mentales.',
          'Pacientes en estado de ebriedad o con abuso de alguna droga que se encuentren en estado agresivo o no deseen ser trasladados. ',
          'Cuando el usuario no proporcione información veraz y oportuna, o que incurra en falsedad de información en relación con el tipo de lesiones. ',
          'Ambulancias programadas. ',
          'Ambulancias aéreas.',
        ],
        'Asistencia Legal',
        'Si te detiene un Policía o quedas a disposición de alguna autoridad a consecuencia de un percance de tránsito, enviaremos a un abogado presencial al lugar de los hechos para tu defensa legal.',
        'El servicio incluye un evento por año a nivel nacional.',
        [
          'Servicio profesional de abogados especialistas en asuntos vehiculares judiciales / administrativos.',
          'Defensa y/o asesoría ante las autoridades por la comisión de delitos culposos en materia de responsabilidad derivados del tránsito terrestre de vehículos.',
          'Atención 24 hrs del día / 365 días al año.',
        ],
        'Exclusiones: ',
        'No incluye fianzas ni cauciones. ',
        'Outo quedará eximido del cumplimiento de las obligaciones en los siguientes casos: ',
        [
          'Cuando el usuario no proporcione información veraz y oportuna, que por su naturaleza no permita al abogado evaluar debidamente el asunto. ',
          'Cuando el usuario no acuda a las audiencias o citatorios realizados por la autoridad. ',
          'Cuando el usuario no atienda la asesoría y recomendaciones realizadas por el abogado para su defensa, o realice arreglos personales sin consultar previamente al profesionista mencionado. ',
          'Cuando el usuario designe un abogado distinto a los proporcionados por Outo, lo que implique que esta empresa pierda el control del asunto. ',
          'En los casos en que el usuario y/o beneficiarios conduzcan vehículos con capacidad de carga de 3.5 toneladas en adelante, vehículos de transporte público de carga y de personas tanto federal como particular (taxis, microbuses, colectivos, autobuses y vehículos arrendados). ',
        ],
        'Outo no cubrirá el importe de las multas por infracciones al reglamento de tránsito y derechos de uso de piso (corralón). ',
        'Outo no se hace responsable de cubrir la responsabilidad civil (daños en propiedad ajena), en que incurra el usuario y/o beneficiarios, por algún hecho relacionado con el tránsito terrestre, esto quiere decir, que Outo no se hace responsable del pago de daños causados a terceros, tanto en sus personas como en sus bienes, ni del pago de indemnizaciones ni garantías civiles que se llegaran a generar a cargo del usuario y/o beneficiarios por tales hechos. ',
        'Los servicios de Emergencia Vial serán válidos y aplicables al haber transcurrido un mínimo de quince (15) días posteriores al pago del servicio. ',
      ]
    },
    {
      title: '3.- Costos:',
      content: [
        'Alertas',
        'El costo de la suscripción anual del servicio de Alertas es de: $399 pesos (TRESCIENTOS NOVENTA Y NUEVE PESOS 00/100 M.N.). El precio no incluye I.V.A. ',
        'La renovación de la suscripción se realiza cada año y los servicios no son acumulables.',
        'Alertas Plus',
        'El costo de la suscripción anual del servicio de Alertas Plus es de: $699 pesos (SEISCIENTOS NOVENTA Y NUEVE PESOS 00/100 M.N.). El precio no incluye I.V.A. ',
        'La renovación de la suscripción se realiza cada año y los servicios no son acumulables.',
        'Emergencia Vial',
        'El costo de la suscripción anual del servicio de Emergencia Vial es de: $499 pesos (CUATROCIENTOS NOVENTA Y NUEVE PESOS 00/100 M.N.). El precio no incluye I.V.A. ',
        'La renovación de la suscripción se realiza cada año y los servicios no son acumulables.',
        'Los precios están sujetos a cambio sin previo aviso, los cuales serán actualizados en la aplicación móvil de Outo o sitio web www.outo.mx.',
      ]
    },
    {
      title: '4.- Pagos',
      content: [
        'El pago de las suscripciones se pueden realizar por medio de los siguientes métodos de pago:',
        [
          'Tarjetas de débito / crédito',
          'PayPal',
        ],
        'Las formas de pagos y condiciones podrán variar sin previo aviso, las cuales serán actualizadas en la aplicación móvil de Outo o sitio web www.outo.mx.',
      ]
    },
    {
      title: '5.- Reembolsos',
      content: [
        'La suscripción del servicio de Alertas, Alertas Plus y Emergencia Vial se pagan de forma anual por adelantado, por lo tanto, no hay reembolsos ni devoluciones de dinero, ni tampoco reembolsos por fracciones de periodo de pago por uso de los servicios.'
      ]
    },
    {
      title: '6.- Responsabilidades',
      content: [
        'Outo no se hace responsable de realizar ningún servicio cuando:',
        [
          'El usuario no se identifique como usuario activo de la plataforma. ',
          'El usuario no proporcione información veraz y no se permita atender debidamente el servicio o incurra en falsedad de declaraciones.',
          'Cuando las placas registradas por el usuario no son de los Estados participantes y no seleccione participar como usuario Foráneo.',
          'Cuando las placas registradas por el usuario son Federales.',
          'Por disposición de la Autoridad Federal, Estatal o local y/o protocolos emitidos por Autoridades correspondientes en México.',
        ],
        'Outo no se hace responsable de las notificaciones enviadas a los usuarios ni tampoco si el vehículo registrado no cumple con sus obligaciones y esto le genera multas o recargos. Tampoco nos hacemos responsables de los pagos pendientes según las obligaciones del usuario y requisitos que tiene que cumplir en cada Estado.',
        'Outo podrá rechazar cualquier solicitud que considere deshonesta o de mala fe por parte del usuario.',
        'Para cualquier duda o aclaración, escríbanos a hola@outo.mx',
      ]
    }

  ]
}
export const conditionsSlice = createSlice({
  name: 'conditions',
  initialState: {
    emergency,
    managmentConditions,
    alertConditions,
  },
})


export default conditionsSlice.reducer