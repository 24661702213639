import React from 'react'
import Images from '../../helpers/Images'
import './Stores.css'

export const Stores = () => {
    return (
        <div className="wrapper_stores">
            <img
                className="logos"
                alt=""
                src={Images.outo.appStore} 
                onClick={() => window.open("https://apps.apple.com/app/apple-store/id1633109274?pt=125266509&ct=OutoWebsiteHome&mt=8", "_blank")}
                />
            <img
                className="logos"
                alt=""
                src={Images.outo.playStore} 
                onClick={() => window.open("https://play.google.com/store/apps/details?id=com.outoapp&referrer=utm_source%3Dwebsite%26utm_medium%3Dhome-link%26utm_campaign%3Dwebsite%26anid%3Dadmob", "_blank")}                
                />
                
        </div>
    )
}
