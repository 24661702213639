import React, { useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import Images from '../helpers/Images'
import { Stores, ModalVideo } from '../component'
import './Introduction.css'
import { Slide } from '@mui/material'

const Introduction = () => {
  const [showModal, setShowModal] = useState(false)

  const companies = [
    {
      src: Images.outo.automovil,
      alt: "Automóvil",
      width: 127
    },
    {
      src: Images.outo.reforma,
      alt: "Reforma",
      width: 148
    },
    {
      src: Images.outo.autocosmos,
      alt: "Autocosmos",
      width: 237
    },
    {
      src: Images.outo.el_universal,
      alt: "El_universal",
      width: 108
    },
    {
      src: Images.outo.grupo_formula,
      alt: "Grupo_formula",
      width: 222
    },
    {
      src: Images.outo.milenio,
      alt: "Milenio",
      width: 125
    }

  ];

  const messages = [
    {
      src: Images.outo.message1,
    },
    {
      src: Images.outo.message2,
    },
    {
      src: Images.outo.message3,
    },
    {
      src: Images.outo.message4,
    }
  ];

  const [message, setMessage] = useState(1)


  useEffect(() => {
    setInterval(() => {
      let valor = Math.floor(Math.random() * (5 - 1)) + 1
      setMessage(valor)
    }, 5000);
  }, []);
  const text_intro = 'Recibe Alertas Vehiculares cuando te pongan una multa o fotocívica, tengas que pagar tenencia, verificar tu auto, renovar tu tarjeta de circulación o cuando no circules. Además tienes 5 servicios disponibles de Emergencia Vial a nivel nacional.'
  return (
    <div id="introduction" className="introduction" >
      <div className="intro_wrap">
        <div className="left_container"  >
          <h1 className="title desktop">
            Todo tu auto…
            <br className="desktop" />
            en una sola app
          </h1>
          <h1 className="title mobile">
            Todo tu auto… <br /> en una sola <br /> aplicación
          </h1>
          <div className="screen_container_mobile">
            <img alt="" className="king" src={Images.outo.king_pink} />
            {
              messages.map(({ src }, item) => (
                <Slide key={`into_message_${item}`} className='into_message' direction="left" in={message === (item + 1)} mountOnEnter unmountOnExit
                  {...(message === (item + 1) ? { timeout: 1000 } : {})}
                >
                  <img alt='message' src={src} />
                </Slide>
              ))
            }
          </div>
          <div className="subtitle_container ">
            <p >Descarga la app y registra tu auto  o moto en 3 min</p>
          </div>
          <div className="description_container desktop">
            <span className="section_description">{text_intro}</span>
          </div>
          <div className="stores_container" >
            <div className='desktop'>
              <Stores />
            </div>
            <img className="play_icon" alt="" src={Images.outo.play} onClick={() => setShowModal(true)} />
            <p className='watch_video' onClick={() => setShowModal(true)}>Ver video</p>
          </div>
        </div>
        <div className="description_container_mobile">
          <span className="description_mobile">{text_intro}</span>
        </div>
        <div className="right_container desktop ">
          <img alt="" className='screen_outo ' src={Images.outo.king_pink} />
          {
            messages.map(({ src }, item) => (
              <Slide key={`slide${item}`} className='into_message' direction="left" in={message === (item + 1)} mountOnEnter unmountOnExit
                {...(message === (item + 1) ? { timeout: 1000 } : {})}
              >
                <img alt='slide' src={src} />
              </Slide>
            ))
          }
        </div>
      </div>

      <div className="companies desktop">
        <Fade direction="up" triggerOnce>
          {
            companies.map(({ src, alt, width }, i) => (
              <img key={`company_${i}`} className="company" src={src} alt={alt} width={`${width}px`} />
            ))
          }
        </Fade>
      </div>
      <div className="wrap-modal-video">
        {showModal &&
          <ModalVideo
            Id='I9T50dmpTMY'
            setShowModal={setShowModal}
          />
        }
      </div>
    </div>
  )
}


export default Introduction;