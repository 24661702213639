import React from 'react'
import './ColumnList.css'
export const ColumnList = ({ title, list }) => {
    return (
        <div className="column_list" >
            <p>{title}</p>
            <ul>
                {
                    list.map(({ name, onClick }, i) => {
                        return (
                            <li
                                key={`${title}_${i}`}
                                className="column_li" 
                                onClick={onClick}
                            >
                                {name}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}
