import React from 'react'
import './OpinionCard.css'

export const OpinionCard = ({ name, occupation, opinion, image }) => {
  return (
    <div className="opinion_card">
      <div className="opinion_header">
        <img alt="" src={image} />
        <div className="person">
          <span className="title">{name}</span>
          <span className="subtitle">{occupation}</span>
        </div>
      </div>
      <div className="description">
        <span>{opinion}</span>
      </div>
    </div>
  )
}
