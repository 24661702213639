import { Box, Divider, Drawer, List, ListItem, ListItemText } from '@mui/material';
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setCurrentHeader, setSection } from '../../features/headerSlice';
import Images from '../../helpers/Images';
import { goToSection } from '../../helpers/utils';
import './HeaderMenu.css'

export const HeaderMenu = forwardRef((props, refH) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [path, setPath] = useState('/')
  const [showMenu, setShowMenu] = useState(false);
  const [showFixedClass, setFixedClass] = useState(false);
  const { banner } = useSelector(state => state.header);


  const menu = [
    {
      label: 'Blog',
    },
    {
      label: 'Servicios',
      page: '',
      section: 'services'
    },
    {
      label: 'Precios',
      page: 'price',
      section: 'prices'
    },
    {
      label: 'Preguntas frecuentes',
      page: 'faq',
      section: 'faq'
    },
    {
      label: 'Legales',
      page: "legal",
      section: "legal"
    },
    {
      label: 'Contacto: ',
      subfix: 'hola@outo.mx',
      onClick: () => window.location.href = "mailto:hola@outo.mx"
    },
  ];

  const menuList = [
    {
      label: 'Blog',
    },
    {
      label: 'Servicios',
      page: '',
      section: 'services'
    },
    {
      label: 'Precios',
      page: 'price'
    },
    {
      label: 'Preguntas Frecuentes',
      page: 'faq'
    },
    {
      label: 'Recomendar con amigos',
    },
    {
      label: 'Legales',
      page: "legal",
    },
    {
      label: 'Contacto',
      onClick: () => window.location.href = "mailto:hola@outo.mx"
    },
  ];


  const goToPage = (page, section) => {
    const y = refH.current.getBoundingClientRect().bottom;
 
    dispatch(setCurrentHeader({ height: y }))
    dispatch(setSection({ section: section }))
    
    if(page === pathname.replace('/', '')){
      goToSection(section, y)
    }

    setPath(page);
    navigate(page)
  }
  const header = useRef();

  useEffect(() => {
    const y = refH.current.getBoundingClientRect().bottom;
    dispatch(setCurrentHeader({ height: y }));
    setPath(pathname.replace('/', ''));
    document.addEventListener('scroll', (e) => {
      setFixedClass(!(window.scrollY === 0))
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div ref={header} className={`header_container ${(showFixedClass) ? "fixed_position" : ''}  `}>
        <header className={`${(banner) ? "downside" : ''}`}>
          <div ref={refH} className={'header'}>
            <Link to="/" className="logo_container">
              <img className="logo_outo" src={Images.outo.logo} alt="outo_icon" onClick={() => goToPage('', null)} />
            </Link>
            <nav className='nav_menu' >
              <ul className='list_menu' >
                {
                  menu.map(({ label, subfix, page, section, offset, onClick }, i) => (
                    <li key={`label${i}`} className="li_menu desktop " >
                      <div className={`${page && page === path && "selected"}`}>
                        <span className="menu_label"
                          onClick={() => {
                            onClick ? onClick() : goToPage(page, section, offset)
                          }
                          }
                        >
                          {label}
                          {subfix && <span className="menu_subfix">{subfix}</span>}
                        </span>
                      </div>
                    </li>
                  ))
                }
                <li className="menu_button">
                  <img className="menu_icon" alt="icon" src={Images.icons.menu} onClick={() => setShowMenu(true)} />
                </li>
              </ul>
            </nav>
          </div>
        </header>
      </div>
      <Drawer anchor='right' open={showMenu} onClose={() => setShowMenu(false)}>
        <Box sx={{ width: 300 }} role="presentation"
          onClick={() => setShowMenu(false)}
          onKeyDown={() => setShowMenu(false)}
        >
          <>
            <h1 className="side_menu_title">Menú</h1>
            <List className="side_menu_list">
              {
                menuList.map(({ label, page, section, add, onClick }, i) => (
                  <div key={`item_${i}`}>
                    <ListItem>
                      <ListItemText primary={label} style={{ paddingLeft: '10px' }}
                        onClick={() => {
                          onClick ? onClick() : goToPage(page, section)
                        }}
                      />
                    </ListItem>
                    {i !== menuList.length - 1 && <Divider />}
                  </div>
                ))
              }
            </List>
          </>
        </Box>
      </Drawer>
    </>


  )
})