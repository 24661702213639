const Images = {
    outo: {
        logo: require('../assets/images/outo/outo_logo.png'),
        logo_O: require('../assets/images/outo/logo_O.png'),
        logo_white: require('../assets/images/outo/logo_white.png'),
        //Intro
        message1: require('../assets/images/outo/messages/message1.png'),

        message2: require('../assets/images/outo/messages/message2.png'),
        message3: require('../assets/images/outo/messages/message3.png'),
        message4: require('../assets/images/outo/messages/message4.png'),


        screens_app: require('../assets/images/outo/screens_app.png'),
        screen_app: require('../assets/images/outo/screen_app_menu.png'),
        autocosmos: require('../assets/images/outo/autocosmos_logo.png'),
        automovil: require('../assets/images/outo/automovil_logo.png'),
        el_universal: require('../assets/images/outo/el_universal_logo.png'),
        grupo_formula: require('../assets/images/outo/grupo_formula_logo.png'),
        milenio: require('../assets/images/outo/milenio_logo.png'),
        reforma: require('../assets/images/outo/reforma_logo.png'),
        play: require('../assets/images/outo/play.png'),
        service: require('../assets/images/outo/service.png'),
        technology: require('../assets/images/outo/technology.png'),
        warranty: require('../assets/images/outo/warranty.png'),
        support: require('../assets/images/outo/support.png'),
        draw_crane: require('../assets/images/outo/draw_crane.png'),
        alert: require('../assets/images/outo/alert.png'),
        legal_assistance: require('../assets/images/outo/legal_assistance.png'),
        ambulance: require('../assets/images/outo/ambulance.png'),
        agency: require('../assets/images/outo/agency.png'),
        account:require('../assets/images/outo/account.png'),
        police: require('../assets/images/outo/police.png'),
        wallet: require('../assets/images/outo/wallet.png'),
        road_aid: require('../assets/images/outo/road_aid.png'),
        crane: require('../assets/images/outo/crane.png'),
        insurance: require('../assets/images/outo/insurance.png'),
        alerts_screen: require('../assets/images/outo/alertas.png'),
        policeman: require('../assets/images/outo/policeman.png'),
        payment_screen: require('../assets/images/outo/payments.png'),
        paying: require('../assets/images/outo/paying.png'),
        icon_app: require('../assets/images/outo/icon_app.png'),
        exclusive_benefits: require('../assets/images/outo/exclusive_benefits.png'),
        person1: require('../assets/images/outo/person1.png'),
        person2: require('../assets/images/outo/person2.png'),
        person3: require('../assets/images/outo/person3.png'),
        facebook: require('../assets/images/outo/facebook.png'),
        twitter: require('../assets/images/outo/twitter.png'),
        instagram: require('../assets/images/outo/instagram.png'),
        linkedin: require('../assets/images/outo/linkedin.png'),
        appStore: require('../assets/images/outo/appStore.png'),
        playStore: require('../assets/images/outo/playStore.png'),
        mechanical: require('../assets/images/outo/mechanical.png'),
        verification: require('../assets/images/outo/verification.png'),
        background: require('../assets/images/outo/background.svg').default,
        star: require('../assets/images/outo/star.png'),
        outo_icono: require('../assets/images/outo/outo_icono.png'),
        pack_outo_3x: require('../assets/images/outo/pack_outo_3x.png'),
        king_pink: require('../assets/images/outo/king_pink.png'),
        crown: require('../assets/images/outo/crown.png'),
        red_call: require('../assets/images/outo/red_call.png'),
        outo_mini: require('../assets/images/outo/outo_mini.png'),
        woman: require('../assets/images/outo/woman.png'),
        insurances: require('../assets/images/outo/insurance_menu.png'),
        dashboard: require('../assets/images/outo/dashboard.png'),
        background_dots: require('../assets/images/outo/background_dots.png'),

        services: {
            ambulance: require('../assets/images/outo/services/ambulance.png'),
            assitant_legal: require('../assets/images/outo/services/assitant_legal.png'),
            auto_crane: require('../assets/images/outo/services/auto_crane.png'),
            lawyer_aid: require('../assets/images/outo/services/lawyer_aid.png'),
            road_aid_photo: require('../assets/images/outo/services/road_aid_photo.png'),
        }
    },
    icons: {
        menu: require('../assets/images/icons/menu.png'),
        dot: require('../assets/images/icons/dot.png'),
        dot_active: require('../assets/images/icons/dot-active.png'),
        chevron_down: require('../assets/images/icons/chevron-down.png'),
        chevron_up: require('../assets/images/icons/chevron-up.png'),
        back: require('../assets/images/icons/back.png'),
        remove: require('../assets/images/icons/remove.png'),
        circle_check: require('../assets/images/icons/circle_check.png'),
        back_arrow: require('../assets/images/icons/back_arrow.svg').default,
    }
}

export default Images;