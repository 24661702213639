import { createSlice } from "@reduxjs/toolkit"

export const headerSlice = createSlice({
    name: 'header',
    initialState: {
        height: 0,
        banner: false,
        section: null
    },
    reducers: {
        setCurrentHeader: (state, action) => {
            state.height = action.payload.height
        },
        setBanner: (state, action) => {
            state.banner = action.payload.banner
        },
        setSection: (state, action) => {
            state.section = action.payload.section
        }
    }
})

export const { setCurrentHeader, setBanner,setSection } = headerSlice.actions

export default headerSlice.reducer

