import React from 'react'
import Images from '../../helpers/Images'
import './CollapseBorderBox.css'

export const CollapseBorderBox = ({ title, text, openBox, setOpenBox }) => {

  return (
    <div className='border_box'>
      <div className='border_box_header' onClick={(e) => { console.log(e); e.preventDefault(); setOpenBox() }}>
        <p className='border_box_title'>{title}</p>
        <img className="box_control" alt="open" src={openBox ? Images.icons.chevron_up : Images.icons.chevron_down}

        />
      </div>
      <div className={`border_box_text ${openBox ? 'open_border_box' : ''}`}>
        {Array.isArray(text) ?
          text.map((item, i) => (
            <div key={`${i}`}>
              {Array.isArray(item) ?
                <ul>
                  {
                    item.map((li, i) => (
                      <li key={`li${i}`}>{li}</li>
                    ))
                  }
                </ul>
                :
                <p key={i}>{item}</p>
              }
            </div>
          ))
          : <p >{text}</p>
        }
      </div>
    </div>
  )
}
