import { Button } from '@mui/material'
import React from 'react'
import { isAndroid } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { setBanner, setCurrentHeader } from '../../features/headerSlice'
import Images from '../../helpers/Images'
import './DownloadAppBanner.css'

export const DownloadAppBanner = () => {
    const dispatch = useDispatch();
    const { height, banner } = useSelector(state => state.header);

    const onClose = () => {
        const header_height = height - 67;
        dispatch(setCurrentHeader({ height: header_height }))
        dispatch(setBanner({ banner: false }))
    }

    return (
        <div className={`wrap_download_banner ${banner ? '' : 'hide'}`}>
            <div className="download_app_banner">
                <div className='wrap'>
                    <div className="close">
                        <img
                            alt='Cerrar'
                            src={Images.icons.remove}
                            onClick={() => { onClose() }}
                        />
                    </div>
                    <div className="logo">
                        <img
                            alt=''
                            src={Images.outo.outo_icono}
                        />
                    </div>
                    <div className='app_description'>
                        <p>Outo</p>
                        <span>Descarga la App y registra tu auto o moto en menos de 3 min</span>
                        {
                            <div className='star_wrap'>
                                {
                                    [...Array(5)].map((item, i) => (
                                        <img alt='' key={`star${i}`} className='star' src={Images.outo.star} />
                                    ))}
                            </div>
                        }
                    </div>
                    <div className='download'>
                        <Button className="button_app"
                            onClick={() => window.open(`${isAndroid ? 'https://play.google.com/store/apps/details?id=com.outoapp' : 'https://apps.apple.com/mx/app/outo/id1633109274'}`, "_blank")}
                        >
                            Descargar
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    )
}
