import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCurrentBackground } from '../features/backgroundSlice'
import Images from '../helpers/Images'
import './Answers.css'

const Answers = () => {
    const dispatch = useDispatch();
    const { current } = useSelector(state => state.question)
    const { name, questions } = current;
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/doubts')
    }

    useEffect(() => {
        dispatch(setCurrentBackground({ background: 'gradient' }))
        window.scrollTo(0, 0)
    // eslint-disable-next-line
    }, []);

    return (
        <div className='answers container'>
            <div className='row'>
                <h1>{name}</h1>
                <Button className={`button_light`}
                    onClick={() => { goBack() }}
                >
                    <img alt=''
                        src={Images.icons.back_arrow}
                    />
                    Regresar
                </Button>
            </div>
            {
                questions.map(({ question, answer }, i) => (
                    <div key={`qa_${i}`} className='horizontal_box'>
                        <div className='content'>
                            <h3>{question}</h3>
                            {
                                Array.isArray(answer) ?
                                    answer.map((item, i) => (
                                        <p key={`child_qa${i}`}>{item}</p>
                                    ))
                                    :
                                    <p>{answer}</p>

                            }
                        </div>
                    </div>
                ))
            }


        </div>
    )
}

export default Answers