import { configureStore } from '@reduxjs/toolkit'
import  backgroundSlice from '../features/backgroundSlice'
import headerSlice from '../features/headerSlice'
import faqSlice from '../features/faqSlice'
import termsAndConditionsSlice from '../features/termsAndConditionsSlice'
import { loadState, saveState } from '../helpers/storeState'
import conditionsSlice from '../features/conditionsSlice'

const store = configureStore({
  preloadedState: loadState(),
  reducer: {
    header: headerSlice,
    background: backgroundSlice,
    question: faqSlice,
    legals: termsAndConditionsSlice,
    conditions: conditionsSlice
  }
})
store.subscribe( () => {
  saveState(store.getState())
}

)
export default store