import React, { useRef } from 'react'
import { AlertService, ServiceInfo } from './'
import './Services.css'


const Services = ({header}) => {
  const myRef = useRef();
  const myRefP = useRef();
  const infoRef = useRef();


  return (
    <div id="services" className="services">
      <div ref={myRefP} className="text_servicesParent">
      </div>
      <div ref={myRef} className={`text_services`}>
        <h1 className='section_subtitle'>Nuestros servicios</h1>
        <p className='section_description'>Queremos que tengas el control total de tu auto o moto en México, recibas alertas vehiculares en tiempo real y asistencia vial en cualquier emergencia</p>
      </div>
      <AlertService />
      <ServiceInfo
        ref={infoRef}
      />
    </div>
  )
}

export default Services;
