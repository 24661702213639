import React, { useEffect, useRef } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './App.css'
import { DownloadAppBanner, Footer, HeaderMenu } from './component'
import { setBanner } from './features/headerSlice'
import { PrivateRoutes } from './router/PrivateRoutes'
const App = () => {
  const dispatch = useDispatch();
  const { banner } = useSelector(state => state.header)

  useEffect(() => {
    const ban = isAndroid || isIOS ? true : false;
    dispatch(setBanner({ banner: ban }))
    // eslint-disable-next-line
  }, [])

  const refH = useRef();

  return (
    <div className="app">
      {
        banner &&
        < DownloadAppBanner
        />
      }
        <BrowserRouter>
          <HeaderMenu ref={refH} />
          <PrivateRoutes header = {refH} />
          <Footer />
        </BrowserRouter>
    </div>
  )
}


export default App;

