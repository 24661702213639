import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { CollapseBox, CollapseBorderBox } from './'
import './Questions.css'

const Questions = () => {

  const { list } = useSelector(state => state.question)

  const [open, setOpen] = useState(isMobile ? null : 0)
  const [openBox, setOpenBox] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, []);

  return (
    <div className='question_wrapper'>
      <div className='question_background'>
      </div>
      <div className='questions'>
        <div className={`text_services`}>
          <h1 className='section_subtitle'>Preguntas frecuentes</h1>
        </div>
        <div className='row'>
          <div className='left'>
            <div className='column_box'>
              {
                list.map(({ name, icon, questions }, i) => {
                  return <CollapseBox
                    open={open === i}
                    setOpen={() => { setOpen(isMobile ? (i === open ? null : i) : i); setOpenBox(null) }}
                    name={name}
                    icon={icon}
                    key={`faq_${i}`}
                  >
                    {
                      <div key={`faqs_${i}`} className={`faqs_panel ${open === i ? 'visible' : 'no_visible'}`}>
                        {questions.map(({ question, answer }, key) => (
                          <CollapseBorderBox
                            key={`question ${key}`}
                            title={question}
                            text={answer}
                            openBox={openBox === key}
                            setOpenBox={() => {
                              setOpenBox(key === openBox ? null : key)
                            }}
                          />
                        ))}
                      </div>
                    }
                  </CollapseBox>
                })
              }
            </div>
          </div>
          <div className='right desktop'>
            {
              list.map(({ questions }, i) => (
                <div key={`faqs_${i}`} className={`faqs_panel ${open === i ? 'visible' : 'no_visible'}`}>
                  {questions.map(({ question, answer }, i) => (
                    <CollapseBorderBox
                      key={`question ${i}`}
                      title={question}
                      text={answer}
                      openBox={openBox === i}
                      setOpenBox={() => {
                        setOpenBox(i === openBox ? null : i)
                      }}
                    />
                  ))}
                </div>
              ))


            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Questions