import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import './Emergency.css';
const Emergency = () => {
  const { emergency } = useSelector(state => state.conditions);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className='container'>
    <div  className='text_container_conditions'>
    <h1>{emergency.title}</h1>
    {
      emergency.intro &&
      <div>
        <div>
          <div >
            {
              Array.isArray(emergency.intro) ?
                emergency.intro.map((item, i) => (
                  <p key={`intro_${i}`} >{item}</p>
                ))
                :
                <p>{emergency.intro}</p>
            }
          </div>
          {
            emergency.clauses &&
            emergency.clauses.map(({ title, content }, i) => (
              <div key={`content_${i}`} className='text_block'>
                <p><b>{title}</b></p>
                {
                  Array.isArray(content) ?
                    content.map((item, i) => (
                      Array.isArray(item) ? (
                        <ul key={`term_ul_${i}`}>
                          {
                            item.map((list_item, i) =>
                              <li key={`term_li_${i}`}>
                                {list_item}
                              </li>
                            )
                          }
                        </ul>
                      ) : (
                        <p key={`term_p_${i}`} >{item}</p>
                      )
                    )) : <p>{content}</p>
                }
              </div>
            ))
          }
        </div>
      </div>
    }
  </div>
  </div>
  )
}

export default Emergency