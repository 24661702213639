import React from 'react'
import Images from '../helpers/Images'
import './PriceInfo.css'

export const PriceInfo = ({ title, subtitle, list, total, letters, children }) => {
  return (
    <div className='price_info'>
      <div className='header_box'>
        <span className='title_box'>{title}</span>
        <span className='subtitle_box'>{subtitle}</span>
      </div>
      <div className='price_box'>
        <div>
          {
            list.map(({ label, price }, i) => (
              <div key={`price ${i}`} className='item_list'>
                <div className='row' >
                  <img alt='check' src={Images.icons.circle_check} />
                  <span>{label}</span>
                </div>
                <span className='price'>{price}</span>
              </div>
            ))
          }
          <div className='total'>
            <div>
              <span > <b>Total: </b></span>
              <span className='price'>{total}</span>
            </div>

          </div>
          <div className='additional_text'>
            {children && children}
          </div>
        </div>
      </div>
      <div className='complement'>
        {
          letters.map((letter, i)=>(
            <span key={i}>{letter}</span>
          ))
        }
      </div>
    </div>

  )
}
