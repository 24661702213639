import React from 'react'
import { OpinionCard } from '../component'
import Images from '../helpers/Images'
import './Opinions.css'

const Opinions = () => {
  const list = [
    {
      name: "Ernesto Carmona",
      occupation: "Ingeniero Civil",
      opinion: "Antes de tener Outo, siempre me olvidaban las fechas para hacer la verificación y me cobraban multas.",
      image: Images.outo.person1
    },
    {
      name: "Jesús Santillana",
      occupation: "Profesor",
      opinion: "Outo es una muy buena solución para revisar las multas de mi auto y el de mi esposa.",
      image: Images.outo.person2
    },
    {
      name: "Leticia Ramírez C.",
      occupation: "Ama de Casa",
      opinion: "Me da mucha tranquilidad tener Outo y los servicios de asistencia para cualquier emergencia.",
      image: Images.outo.person3
    }
  ]
  return (
    <div className="opinions">
      <h1 className="section_subtitle">Opiniones de nuestros clientes</h1>
      <div className="container_row">
        {
          list.map(({ name, occupation, opinion, image }, i) => {
            return (
              // <Fade  key={`opinion${i}`} direction="up" triggerOnce>
              <OpinionCard
                key={`opinion${i}`}
                name={name}
                occupation={occupation}
                opinion={opinion}
                image={image}
              />
              // </Fade>
            )
          })
        }
      </div>
    </div >
  )
}

export default Opinions