
export const goToSection = (section, offset = 0) => {
    const doc = document.querySelector('#' + section);

    if (doc) {
        const top = doc.offsetTop - window.scrollY - offset;
        window.scrollBy({ behavior: "smooth", top: top })
    }


}