import { Button } from '@mui/material'
import React, { useState } from 'react'
import Images from '../../helpers/Images';
import './CardBox.css'
import { IconList } from './IconList';

export const CardBox = ({ plus, title, icon, price, description, list, note, additional_text, additional_list }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={`cardbox ${plus ? 'cardbox_plus' : ''} ${open ? 'open_cardbox' : ''}`}>
      {plus &&
        <>
          <div className='intro_plus'>
            <span>Más Popular</span>
          </div>
          <div className='label_price'>
            <span>Precio especial</span>
          </div>
        </>
      }
      <div className='cardbox_content'>
        <div className='title_row'>
          <p className='service_title '>{title}</p>
          {icon && <img alt='service_icon' src={icon} />}
        </div>
        <span className='price '>{price}</span>
        <span className='section_description small'>El precio no incluye IVA</span>
        <p className='section_description'> {description}</p>
        {
          open
          &&
          <>
            <div className='list'>
              <IconList
                list={list}
                icon={Images.icons.circle_check}
              />
            </div>
            <p className='note'>{note}</p>
          </>
        }
        {!open && additional_text && <span className='addition'> + </span>}
        {additional_text &&
          <p className='section_description'> {additional_text}</p>
        }
        {
          open
          &&
          additional_list &&
          <div className='additional_list'>
            <IconList
              list={additional_list}
              icon={Images.icons.circle_check}
            />
          </div>
        }
        <Button
          disableRipple
          className='show_button '
          onClick={() => setOpen(!open)}
        >
          {open ? 'Ver menos' : 'Ver más'}
        </Button>

      </div>

    </div >
  )
}
