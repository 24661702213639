import React, { useEffect } from 'react'
import './Home.css'
import { Introduction, Services, Managment, Insurance, Opinions, Dashboard } from '../component'
import { isMobile } from 'react-device-detect'
import {useSelector } from 'react-redux';
import { goToSection } from '../helpers/utils'

const Home = ({ header }) => {
  const { height, banner, section } = useSelector(state => state.header);
  const verifySection = () => {
    if (section) {
      goToSection(section, height);
    }
    else {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 100);
    }
  }

  useEffect(() => {
    verifySection();
    // eslint-disable-next-line
  }, [section])

  useEffect(() => {
    verifySection();
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div id="container_home" className={`container_home ${(banner) ? "down" : ''}`}>
        <div className={`introduction_background ${isMobile ? 'gradient' : ''}`} >
          <Introduction />
        </div>
        <Services header={header} />
        <div className='gradient payment_background'>
          <Managment />
        </div>
        <Insurance />
        <Dashboard />
        <Opinions />
      </div>
    </>
  )
}

export default Home;
