import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentBackground } from '../features/backgroundSlice';
import './Legal.css'
const Legal = () => {
  const dispatch = useDispatch();
  const { terms, politics} = useSelector(state => state.legals);
  const [texts, setTexts] = useState(terms);
  const [current, setCurrent] = useState(0);
  const Ref = useRef();

  const legals = [
    {
      name: "Términos y Condiciones",
      text: terms
    },
    {
      name: "Políticas de Privacidad",
      text: politics
    },
  ]

  const toTop = () => {
    Ref.current.scrollTo(0, 0);
  }

  useEffect(() => {
    dispatch(setCurrentBackground({ background: 'gradient' }))
    window.scrollTo(0, 0)
    setCurrent(0)
  }, [dispatch])


  useEffect(() => {
    const { text } = legals[current];
    setTexts(text);
    // eslint-disable-next-line
  }, [current]);


  return (
    <div className='container legal '>
      <div className='row'>
        <div className='row'>
          {
            legals.map(({ name }, i) => (
              <div
                key={`legal_${i}`}
                className={current === i ? 'active' : ''}
                onClick={() => {
                  setCurrent(i)
                  toTop()
                }}>
                <span>{name}</span>
              </div>
            ))
          }
        </div>
        <div className="update">
          <span>Actualizados: 14 de noviembre del 2022</span>
        </div>
      </div>
      <div ref={Ref} className='text_container'>
        <h1>{texts.title}</h1>
        {
          texts.intro &&
          <div>
            <div>
              <div >
                {
                  Array.isArray(texts.intro) ?
                    texts.intro.map((item, i) => (
                      <p key={`intro_${i}`} >{item}</p>
                    ))
                    :
                    <p>{texts.intro}</p>
                }
              </div>
              {
                texts.clauses &&
                texts.clauses.map(({ title, content }, i) => (
                  <div key={`content_${i}`} className='text_block'>
                    <p><b>{title}</b></p>
                    {
                      Array.isArray(content) ?
                        content.map((item, i) => (
                          Array.isArray(item) ? (
                            <ul key={`term_ul_${i}`}>
                              {
                                item.map((list_item, i) =>
                                  <li key={`term_li_${i}`}>
                                    {list_item}
                                  </li>
                                )
                              }
                            </ul>
                          ) : (
                            <p key={`term_p_${i}`} >{item}</p>
                          )
                        )) : <p>{content}</p>
                    }
                  </div>
                ))
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Legal