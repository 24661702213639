import { createSlice } from "@reduxjs/toolkit";
import Images from "../helpers/Images";
const faq = [
  {
    name: 'Alertas Vehiculares',
    icon: Images.outo.alert,
    questions: [
      {
        question: '¿Qué son las Alertas Vehiculares?',
        answer: [
          'Son notificaciones en tiempo real cuando te ponen una multa, sanción ambiental o fotocívica, tienes que pagar tu tenencia, verificar tu auto, renovar tus placas o la tarjeta de circulación, tengas un reporte de robo o simplemente cuando no circules.',
          'El servicio de Alertas está disponible para autos y motos con placas registradas en Ciudad de México, Estado de México y Morelos.'
        ],
      },
      {
        question: '¿Cuánto cuesta contratar una Alerta Vehicular?',
        answer: 'La suscripción anual de las Alertas es de $399 pesos más IVA. Puedes cancelar la suscripción en cualquier momento.',
      },
      {
        question: '¿Qué es Alertas Plus, qué incluye y cuanto cuesta?',
        answer: 'Es la suscripción más popular entre nuestros clientes ya que incluyen los servicios de las Alertas Vehiculares más 5 servicios de Emergencia Vial (Grúa, Auxilio Vial, Abogado Vial, Ambulancia y Asistencia Legal). El costo de la suscripción anual es de $699 pesos más IVA. Puedes cancelar la suscripción en cualquier momento.',
      },
      {
        question: '¿Qué pasa con mi pago si cancelo mi suscripción?',
        answer: 'Las suscripciones son anuales y no son transferibles ni reembolsables. Si realizas una cancelación, se verá reflejado hasta el siguiente año ya que de momento no hay devoluciones. ',
      },
      {
        question: '¿El servicio está disponible para motos?',
        answer: 'Sí, también puedes registrar tu moto para cualquier suscripción anual y recibir Alertas y los servicios de Emergencia Vial.',
      },
      {
        question: '¿Qué exclusiones tiene el servicio de Alertas?',
        answer: 'Quedan excluidos los vehículos con placas Federales o placas de Estados no participantes. El servicio no incluye el pago de multas, infracciones o impuestos.',
      },
      {
        question: '¿Cómo me envían las notificaciones?',
        answer: 'Cuando nuestro sistema identifique que tienes alguna multa, estés en periodo de verificación, tengas que pagar tenencia, etc. te enviaremos “push notifications” a tu celular. ',
      },
      {
        question: '¿Puedo contratar una Alerta para cualquier automóvil a nivel nacional?',
        answer: 'Por el momento, el servicio está disponible para los autos con placas registradas en la Ciudad de México, Estado de México y Morelos.',
      },
      {
        question: '¿Puedo pagar los impuestos o multas desde la plataforma?',
        answer: 'Hay algunos servicios como el de Infracciones que puedes consultar el PDF con la línea de captura para que puedas realizar tu pago. Estamos trabajando con los Estados para que los puedas pagar directamente tus impuestos y multas desde la app de Outo.',
      }
    ]
  },
  {
    name: 'Emergencia Vial',
    icon: Images.outo.road_aid,
    questions: [
      {
        question: '¿Cuánto cuesta contratar Emergencia Vial?',
        answer: 'La suscripción anual de Emergencia Vial es de $499 pesos más IVA. Que incluyen 5 servicios de asistencia: Grúa, Auxilio Vial, Abogado Vial, Ambulancia y Asistencia Legal.'
      },
      {
        question: '¿Qué pasa con mi pago si cancelo mi suscripción?',
        answer: 'Las suscripciones son anuales y no son transferibles ni reembolsables. Si realizas una cancelación, se verá reflejado hasta el siguiente año ya que de momento no hay devoluciones. '
      },
      {
        question: '¿Puedo contratar únicamente los servicios de Emergencia Vial?',
        answer: 'Solo en el caso de que ya tengas la suscripción de las Alertas, puedes contratar la suscripción de este servicio.'
      },
      {
        question: '¿Cómo contrato los servicios de Emergencia Vial?',
        answer: 'En el home de la app de Outo, hay un botón que dice: Tengo una Emergencia Vial, al darle clic puedes solicitar el servicio de Grúa, Auxilio Vial, Abogado Vial, Ambulancia y Asistencia Legal.'
      },
      {
        question: '¿Al contratar la suscripción anual, puedo hacer uso de los beneficios al momento de la contratación?',
        answer: 'Deberás esperar un plazo mínimo de 15 días a partir de la contratación y pago del servicio para poder hacer uso de todos los beneficios de Emergencia Vial.'
      },
      {
        question: '¿Puedo usar los servicios de Emergencia Vial para otro vehículo?',
        answer: 'No, los servicios solo se pueden utilizar para el vehículo registrado en la app al momento de contratar el servicio.'
      },
      {
        question: '¿Si solicito un servicio de Grúa, en cuánto tiempo llega?',
        answer: [
          'Nuestro tiempo de arribo máximo para servicios de Grúa en ciudad o área metropolitana es entre 40 a 50 minutos. En servicios carreteros o foráneos, el tiempo de arribo máximo es de 60 a 90 minutos.',
          'Nuestro sistema identifica a la unidad más cercana a tu ubicación con tecnología GPS para que el tiempo de contacto se dé en el menor tiempo posible. El tiempo puede variar según el tráfico en la zona donde te encuentres y la disponibilidad de unidades en el momento de la solicitud.'
        ]
      },
      {
        question: '¿Qué exclusiones tiene el servicio de Grúa?',
        answer: 'Quedan excluidos los vehículos destinados al transporte de carga, taxis, microbuses, colectivos, autobuses, autos modificados y vehículos arrendados. El servicio no cubre casetas, maniobras, abanderamientos y tiempo de espera. El Usuario deberá estar presente en todo momento mientras se realiza el arrastre y/o asistencia.'
      },
      {
        question: '¿Qué pasa si rebaso los kilómetros incluidos en el servicio de Grúa?',
        answer: ['El servicio de grúa local (dentro de la ciudad o área metropolitana) incluye hasta 30 km de arrastre de punto a punto, mientras que el servicio de grúa foránea (fuera del área metropolitana o en tramo carretero) incluye tambien hasta 30 km. ',
          'Si el servicio que solicitas rebasa el kilometraje incluido, el kilómetro adicional se le tendrá que pagar directamente al operador de la Grúa']
      },
      {
        question: '¿Cómo funcional el servicio de Auxilio Vial?',
        answer: [
          'Puedes solicitar el servicio en caso de requerir asistencia con el cambio de llanta, suministro de gasolina o paso de corriente. ',
          'El servicio incluye un evento al año a nivel nacional.',
          [
            'Los eventos pueden ser: cambio de llanta, suministro de gasolina o paso de corriente.',
            'En caso de suministro de gasolina, el usuario tiene incluido hasta un máximo de 10 lts. de gasolina sin costo adicional.',
            'En caso de cambio de llanta, el usuario debe contar con el birlo de seguridad y una llanta de refacción en buenas condiciones.',
            'Atención 24 hrs del día / 365 días al año',
          ]
        ]
      },
      {
        question: '¿Cómo funciona el servicio de Abogado Vial?',
        answer: [
          'Si te detiene un Policía o quedas a disposición de alguna autoridad a consecuencia de un percance de tránsito, nuestros abogados te asesorarán vía telefónica para tu defensa.',
          'El servicio incluye un evento por año a nivel nacional.',
          [
            'Asesoría y orientación telefónica sobre la correcta aplicación del reglamento de tránsito en caso de que te detenga un Policía.',
            'Asesoría y orientación legal en caso de tener un problema vial o infracción de tránsito.',
            'Asesoría para trámites relativos a la liberación del conductor y del vehículo involucrado.',
            'Atención 24 hrs del día / 365 días al año.',
          ]
        ]
      },
      {
        question: '¿Cómo funciona el servicio de Ambulancia?',
        answer: ['El servicio de Ambulancia terrestre está disponible en caso de tener alguna enfermedad repentina, urgencia médica o accidente.',
          'El traslado terrestre es hacia el centro hospitalario más cercano o apropiado en la ciudad donde se encuentre y que se trate de un servicio de emergencia.']
      },
      {
        question: '¿Qué exclusiones tiene el servicio de Ambulancia?',
        answer: [
          'Quedan excluidos los traslados en caso de enfermedades mentales.',
          'Pacientes en estado de ebriedad o con abuso de alguna droga que se encuentren en estado agresivo o no deseen ser trasladados. ',
          'Cuando el usuario no proporcione información veraz y oportuna, o que incurra en falsedad de información en relación con el tipo de lesiones. ',
          'Ambulancias programadas. ',
          'Ambulancias aéreas.',
        ]
      },
      {
        question: '¿Cómo funciona el servicio de Asistencia Legal?',
        answer: [
          'El servicio incluye a un abogado especialista en el lugar de los hechos en caso de tener un accidente automovilístico, para tu defensa ante las autoridades judiciales y/o administrativas',
          'El servicio está disponible a nivel nacional y no incluye fianzas ni cauciones.'
        ]
      }
    ]
  },
  {
    name: 'Gestoría',
    icon: Images.outo.agency,
    questions: [
      {
        question: '¿Cómo funciona el servicio de Gestoría?',
        answer: [
          'La Gestoría o Trámites Vehiculares es válida a nivel nacional e incluye cualquiera de los siguientes servicios:',
          [
            'Alta o Baja de vehículos nuevos o usados',
            'Alta o Baja de vehículos híbridos nuevos o usados',
            'Asistencia Legal por Siniestro y Faltas Administrativas',
            'Pago de Tenencias',
            'Pago de Multas vehiculares y sanciones ambientales',
            'Acta por pérdida de placa y/o tarjeta de circulación y/o facturas o acta por robo P.F.P. ',
            'Reemplacamiento en años posteriores al primero',
            'Reposición de tarjeta de circulación.',
            'Regularización de vehículo con registro de robo',
            'Constancias de no infracción Estatal / Municipal',
            'Corrección de datos y cambio de propietario del vehículo',
            'Inscripción del vehículo al REPUVE'
          ]
        ]
      },
      {
        question: '¿Qué exclusiones tiene el servicio?',
        answer: 'El servicio no incluye el pago de multas, impuestos o infracciones pendientes de pago.'
      },
      {
        question: '¿Cuánto cuesta contratar el Servicio de Gestoría?',
        answer: [
          'La suscripción anual es de $899.00 pesos más IVA e incluye cualquier trámite de los servicios a nivel nacional.',
          'Consulta las Condiciones Generales del servicio para conocer más sobre la cobertura y tipo de trámites vehiculares.'
        ]
      },
      {
        question: '¿Cuáles son los métodos de pago disponibles?',
        answer: 'Puedes pagar el servicio con cualquier tarjeta de crédito o débito (VISA, Mastercard o Amex) y PayPal.'
      },
      {
        question: '¿Qué pasa con mi pago si cancelo mi suscripción?',
        answer: 'Las suscripciones son anuales y no son transferibles ni reembolsables. Si realizas una cancelación, se verá reflejado hasta el siguiente año ya que de momento no hay devoluciones. '
      },
      {
        question: '¿Si contrato la suscripción anual, puedo hacer uso de los beneficios al momento de la contratación?',
        answer: 'Deberás esperar un plazo mínimo de 15 días a partir de la contratación y pago del servicio para poder hacer uso de todos los beneficios.'
      },
      {
        question: '¿Si tengo un problema, qué hago?',
        answer: 'Tenemos un Centro de Atención Digital en donde nos podrás contactar enviándonos un correo electrónico a hola@outo.mx y con mucho gusto te ayudaremos a resolver tus dudas o problemas con la plataforma.'
      },


    ]
  },
  {
    name: 'Seguro',
    icon: Images.outo.insurance,
    questions: [
      {
        question: '¿Tiene un costo el servicio de Seguros?',
        answer: 'No, al registrar los datos básicos de tu Seguro (fecha de contratación, número de póliza y Aseguradora), recibes notificaciones cuando tu Seguro esté por vencer y te ayudamos contratar o renovar un nuevo Seguro desde la app.'

      },
      {
        question: '¿Qué pasa si mi Seguro está por vencer?',
        answer: 'En este caso, recibirás un “push notification” en tu celular para que lo renueves lo antes posible y no te quedes sin Seguro.'

      },
      {
        question: '¿Puedo contratar o renovar un Seguro desde Outo?',
        answer: 'Muy pronto vas a poder contratar el mejor Seguro para tu auto o moto en México, con tarifas y condiciones preferenciales.'

      },
      {
        question: '¿Qué hago en caso de tener un accidente?',
        answer: 'En caso de tener algún accidente, puedes contactar a tu Aseguradora desde la app de Outo haciendo clic en el botón de “Necesito Asistencia”. '

      },
      {
        question: '¿Si tengo un problema, qué hago?',
        answer: 'Tenemos un Centro de Atención Digital en donde nos podrás contactar enviándonos un correo electrónico a hola@outo.mx y con mucho gusto te ayudaremos a resolver tus dudas o problemas con la plataforma.'
      },
    ]
  },
  {
    name: 'Mi Cuenta',
    icon: Images.outo.account,
    questions: [
      {
        question: '¿Qué datos de mi cuenta puedo editar?',
        answer: 'Puedes cambiar tu nombre, apellido y número celular, si necesitas cambiar tu correo nos tienes que enviar la solicitud a hola@outo.mx'
      },
      {
        question: '¿Dónde puedo ver el historial de pagos?',
        answer: 'Dentro de Mi Cuenta, puedes darle clic al botón de Mis Pagos, y ahí podrás ver el historial de todos tus pagos.'

      },
      {
        question: '¿Puedo actualizar un vehículo en caso de venderlo?',
        answer: [
          'Por el momento, si deseas actualizar tu vehículo, es necesario que nos envíes a hola@outo.mx la siguiente información:',
          [
            'Motivo para el cambio de vehículo',
            'Número de tu nueva placa y de qué Estado son',
            'Datos generales de tu nuevo vehículo: marca, modelo, año y color.',
          ]
        ]
      },
      {
        question: '¿Dónde puedo ver el detalle de mis suscripciones?',
        answer: 'En el momento de contratar una suscripción, en la sección de Mi Cuenta, podrás ver cada una de tus suscripciones y el detalle.'

      },
      {
        question: '¿Cuáles son los métodos de pago disponibles?',
        answer: 'Puedes pagar el servicio con cualquier tarjeta de crédito o débito (VISA, Mastercard o Amex) y PayPal.'

      },
      {
        question: '¿Qué pasa con mi pago si cancelo mi suscripción?',
        answer: 'Las suscripciones son anuales y no son transferibles ni reembolsables. Si realizas una cancelación, se verá reflejado hasta el siguiente año ya que de momento no hay devoluciones. '
      },
      {
        question: '¿Si tengo un problema, qué hago?',
        answer: 'Tenemos un Centro de Atención Digital en donde nos podrás contactar enviándonos un correo electrónico a hola@outo.mx y con mucho gusto te ayudaremos a resolver tus dudas o problemas con la plataforma.'
      }

    ]
  },
]


export const faqSlice = createSlice({
  name: 'question',
  initialState: {
    list: faq
  },
  reducers: {
    setCurrentQuestion: (state, action) => {
      state.current = action.payload.current
    }
  }
})

export const { setCurrentQuestion } = faqSlice.actions

export default faqSlice.reducer

