import { Button } from '@mui/material'
import React from 'react'
import Images from '../helpers/Images'
import './Dashboard.css'

const Dashboard = () => {
  return (
    <div className='dashboard'>
      <div className='content'>
        <div className='row'>
          <div className='left'>
            <h1 className='section_subtitle'>Empresas / Flotillas</h1>
            <img className='dashboard_img mobile' alt='dash' src={Images.outo.dashboard} />
            <p className='service_title'>Dashboard para control vehicular</p>
            <p className='section_description'>Creamos una herramienta para que los administradores de flotillas puedan tener en tiempo real un dashboard con la información y estatus completo de cada uno de sus vehículos</p>
            <Button className='show_button border_button'
              onClick= {() => window.location.href = "mailto:hola@outo.mx"}
            >
            Más Información
          </Button>
        </div>
        <div className='right desktop'>
          <img className='dashboard_img' alt='dash' src={Images.outo.dashboard} />
        </div>
      </div>
    </div>
    </div >
  )
}

export default Dashboard