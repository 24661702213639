import React, { forwardRef, useState } from 'react'
import Images from '../helpers/Images'
import './ServiceInfo.css'
import { CollapseBox } from './common/CollapseBox'
import { isMobile } from 'react-device-detect'

const list = [
  {
    name: "Grúa (Local)",
    icon: Images.outo.crane,
    description: "En caso de que tu vehículo no pueda circular por falla mecánica o descompostura en la vía pública, lo remolcaremos al taller o domicilio dentro de la misma ciudad del evento.",
    img: Images.outo.services.auto_crane
  },
  {
    name: "Auxilio Vial",
    icon: Images.outo.road_aid,
    description: "Si tu vehículo presenta fallas mecánicas o descomposturas, podrás solicitar cualquiera de los 3 (tres) servicios disponibles: cambio de llanta, suministro de gasolina o paso de corriente",
    img: Images.outo.services.road_aid_photo
  },
  {
    name: "Abogado Vial",
    icon: Images.outo.police,
    description: "Si te detiene un Policía o quedas a disposición de alguna autoridad a consecuencia de un percance de tránsito, nuestros abogados te asesorarán vía telefónica para tu defensa.",
    img: Images.outo.services.lawyer_aid
  },
  {
    name: "Ambulancia",
    icon: Images.outo.ambulance,
    description: "En caso de enfermedad repentina, urgencia médica o accidente, te enviaremos una ambulancia terrestre para traslado al centro hospitalario más cercano.",
    img: Images.outo.services.ambulance
  },
  {
    name: "Asistencia Legal",
    icon: Images.outo.legal_assistance,
    description: "Si te detiene un Policía o quedas a disposición de alguna autoridad a consecuencia de un percance de tránsito, enviaremos a un abogado presencial al lugar de los hechos para tu defensa legal.",
    img: Images.outo.services.assitant_legal
  },


]

const ServiceInfo = forwardRef((props, ref) => {

  const [open, setOpen] = useState(isMobile ? null : 0);

  return (
    <div ref={ref} className='service_info '>
      <div className='service_info_container'>
        <div className='header_services'>
          <img alt='call' src={Images.outo.red_call} />
          <p className='service_title'>Emergencia Vial</p>
        </div>
        <span className='section_description'>Tienes 5 servicios disponibles de asistencia vial en caso de que tengas alguna emergencia vial:</span>
        <div className='row'>
          <div className='left'>
            <div className='column_box'>
              {
                list.map(({ name, icon, img, description }, i) => {
                  return <CollapseBox
                    open={open === i}
                    setOpen={() => setOpen(isMobile ? i === open ? null : i : i)}
                    name={name}
                    icon={icon}
                    key={`info_${i}`}
                  >
                    <div key={`info_descr_${i}`} className={`service_description ${open === i ? 'visible' : 'no_visible'}`}>
                      <img alt='' src={img} />
                      <p className='description'>{description}</p>
                    </div>
                  </CollapseBox>

                })
              }
            </div>
          </div>
          <div className='right desktop'>
            {
              list.map(({ img, description }, i) => (
                <div key={`info_descr_${i}`} className={`service_description ${open === i ? 'visible' : 'no_visible'}`}>
                  <img alt='' src={img} />
                  <p className='description'>{description}</p>
                </div>

              ))
            }

          </div>
        </div>
        <p className='note'>* Los servicios de Emergencia Vial serán válidos al haber transcurrido un mínimo de quince (15) días posteriores al pago de la suscripción.</p>

      </div>


    </div>
  )
})

export default ServiceInfo