import React from 'react'
import Images from '../helpers/Images'
import './Insurance.css'

const Insurance = () => {
    return (
        <div className='insurance'>
            <div className='content'>
                <h1 className='section_subtitle'>Seguro de auto / moto</h1>
                <p className='section_description'>Recibe notificaciones con el estatus actual de tu Seguro y si prefieres, contrata un nuevo Seguro para tu auto o moto a un precio preferencial de acuerdo a tus necesidades y con las mejores condiciones del mercado.  </p>
                <img alt='insurances' src={Images.outo.insurances}/>
            </div>
        </div>
    )
}

export default Insurance