import React from 'react'
import './IconList.css'


export const IconList = ({list,icon}) => {
  return (
    <div className='icon_list'>
      {
        list.map((label, i) => (
          <div key={`item ${i}`} className='icon_list_item'>
            <img alt='icon' src={icon} />
            <span>{label}</span>
          </div>
        ))
      }
    </div>
  )
}
