import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Alert, Answers, Emergency, Home, Legal, ManagmentConditions, Prices, Questions } from '../component'

export const PrivateRoutes = ({ header }) => {
    return (
        <Routes>
            <Route path="/" element={<Home header={header} />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/emergency" element={<Emergency />} />
            <Route path="/managment" element={<ManagmentConditions />} />
            <Route path="/alerts" element={<Alert />} />
            <Route path="/price" element={<Prices />} />
            <Route path="/faq" element={< Questions />} />
            <Route path="/answers" element={<Answers />} />
        </Routes>
    )
}
