import React from 'react'
import Images from '../../helpers/Images'
import './CollapseBox.css'
export const CollapseBox = ({ name, icon, open, setOpen, children }) => {
  // const { innerWidth: width } = window;
  return (
    <div className={`collapse_wrap ${(open ? "open_box" : "")}`}>
      <div
        className={"collapse_box " + (open ? "open_box" : "")}
        onClick={() => {
          setOpen()
        }}
      >
        <div className="main_box" >
          <div>
            <div className="collapse_icon">
              <img alt="icon" src={icon} />
            </div>
            <p className="main_box_title">{name}</p>
          </div>
          <img
            className="box_control"
            alt="open"
            src={open ? Images.icons.chevron_up : Images.icons.chevron_down}
            onClick={() => {
              setOpen()
            }}
          />
        </div>
      </div>
      <div className={`content mobile ${(open ? "open" : "")}`}>
        {children && children}
      </div>
    </div>

  )
}
