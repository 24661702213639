import { createSlice } from "@reduxjs/toolkit";


export const backgroundSlice = createSlice({
    name: 'background',
    initialState: {
        background: ''
    },
    reducers: {
        setCurrentBackground: (state, action) => {
            state.background = action.payload.background
        }
    }

})

export const {setCurrentBackground} = backgroundSlice.actions

export default backgroundSlice.reducer