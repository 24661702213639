import { Button } from '@mui/material'
import React, { useState } from 'react'
import Images from '../helpers/Images'
import { IconList } from './common/IconList'
import './Managment.css'

const Managment = () => {
  const service_managment = [
    'Alta o Baja de vehículos nuevos o usados',
    'Reemplacamiento en años posteriores al primero',
    'Reposición de tarjeta de circulación',
    'Acta por pérdida de placa y/o tarjeta de circulación y/o facturas o acta por robo P.F.P.',
    'Asistencia Legal por siniestro y faltas administrativas',
    'Corrección de datos y cambio de propietario del vehículo',
    'Pago de Tenencias y multas vehiculares',
    'Inscripción del vehículo al REPUVE',
    'Constancias de no infracción Estatal / Municipal'
  ]

  const [open, setOpen] = useState(false);
  return (
    <div className='managment_service'>
      <div className='content'>
        <h1 className='section_subtitle'>Gestoría / Trámites Vehiculares</h1>
        <div className='box'>
          <div className='row'>
            <div className='left'>
              <p className='service_title mobile'>Servicio de Gestoría</p>
              <img alt='woman' src={Images.outo.woman} />
            </div>
            <div className='right'>
              <p className='service_title desktop'>Servicio de Gestoría</p>
              <p className='section_description'> En caso de que necesites realizar algún trámite vehicular en cualquier Estado a nivel nacional, nuestros Gestores te ayudarán a realizarlo con el mejor servicio y el menor tiempo posible.</p>
              <span className='price '>$899/año</span>
              <span className='section_description small'>El precio no incluye IVA</span>
              <Button
                className='show_button border_button'
                onClick={() => setOpen(!open)}
              >
                {open ? 'Ver menos' : 'Ver más'}
              </Button>
            </div>
          </div>
          <div className={`additional ${open ? 'opened' : 'closed'}`}>
            <div className='list'>
              <IconList
                list={service_managment}
                icon={Images.icons.circle_check}
              />
            </div>
            <p className='note'> * El servicio de Gestoría será válido al haber transcurrido un mínimo de quince (15) días posteriores al pago de la suscripción.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Managment